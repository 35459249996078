
    import Vue from 'vue';
    import { PropType } from 'vue';
    import { SnackbarData } from '@/store/types';

    export default Vue.extend({
        name: 'SnackbarAlert',
        props: {
            data: {
                type: Object as PropType<SnackbarData>,
                required: true
            }
        },
        computed: {
            showSnackbar: {
                get: function() { return this.data.show; },
                set: function() { this.$emit('closed');  }
            },
            huh: () => '',      // TODO: why does TypeScript melt down when this line is removed?
            getColor: function() {
                const ev = this.data.event;

                if(ev == 'success' || ev == 'saved') {
                    return 'success';
                } else if(ev == 'error') {
                    return 'error';
                }
                return "info"
            }
        }
    });
