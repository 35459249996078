
    import { mapGetters } from 'vuex';

    import Vue from 'vue';
    import { ProductVersion } from '@/store/types';

    export default Vue.extend({
        name: 'ProductVersions',
        components: {},
        data() {
            return {
                headers: [
                    {
                        text: 'version',
                        value: 'version'
                    },
                    {
                        text: 'Release Date',
                        value: 'release_date'
                    }
                ],
                sortBy: 'release_date',
                sortDesc: true,
                search: '',
                engineMajorVersions: [
                    'se-23',
                    'se-22',
                    'se-21.1',
                    'se-20.1',
                    'se-2019.1',
                    'se-2018.1'
                ]
            };
        },
        computed: {
            ...mapGetters({
                versions: 'versions/versions',
                driverLink: 'versions/driverLink',
                rxdLink: 'versions/rxdLink'
            }),
            availableVersions: {
                get(): ProductVersion[] {
                    return this.versions('engine');
                }
            }
        },
        created() {
            this.getProductVersions();
        },
        methods: {
            getProductVersions() {
                this.$store.dispatch('versions/getVersions', 'engine');
                this.$store.dispatch('versions/getDriverLinks', 'driver');
                this.$store.dispatch('versions/getDriverLinks', 'rxd');
            },
            handleClick(value: { version: string }) {
                this.$router.push('/productversions/' + value.version);
            },
            async copyDownloadLink(link: string) {
                await navigator.clipboard.writeText(link);
            }
        }
    });
