
    import { mapGetters } from 'vuex';

    import Vue from 'vue';
    export default Vue.extend({
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            }),
            scormDriverUrl() {
                return `https://${this.activeEnv.dns}/ScormEngineInterface/dispatch/scormdriver.js`
            }
        }
    });
