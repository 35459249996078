
    import { mapState } from 'vuex';

    import utilities from '@/mixins/utilities';
    import moment from 'moment';

    import Vue from 'vue';
    import constants from '@/constants';
    import { mhRequestStatus, mhRequestStatusString } from '@/constants';

    export default Vue.extend({
        name: 'RequestList',
        components: {},
        mixins: [utilities],
        data() {
            return {
                headers: [
                    {
                        text: 'Requested Action',
                        value: 'payload_json.action'
                    },
                    {
                        text: 'Product',
                        value: 'payload_json.product'
                    },
                    {
                        text: 'DNS',
                        value: 'payload_json.dnsName'
                    },
                    {
                        text: 'Client Name',
                        value: 'payload_json.clientName'
                    },
                    { text: 'Scheduled Date', value: 'scheduled_date' },
                    { text: 'Status', value: 'status' }
                ],
                sortBy: 'created_date',
                sortDesc: false,
                search: ''
            };
        },
        computed: {
            ...mapState({
                mhrequestsClosed: (state: any) =>
                    state.mhrequests.mhrequests.filter(
                        (item: { status: number }) => item.status !== mhRequestStatus.COMPLETED
                    ),
                mhrequestsOpen: (state: any) =>
                    state.mhrequests.mhrequests.filter(
                        (item: { status: number }) => item.status !== mhRequestStatus.COMPLETED
                    )
            }),
            
        },
        created() {
            this.$store.dispatch('mhrequests/getAllRequests').then(() => {
                this.$store.commit('mhrequests/setInitialized', true);
            });
        },
        methods: {
            requestLink(id: string) {
                this.$router.push('/request/' + id);
            },
            handleClick(value: { id: string }) {
                this.requestLink(value.id);
            },
            getColor(action: any) {
                switch (action) {
                    case 'newenv':
                        return 'green';
                    case 'upgrade':
                        return 'orange';
                    case 'delete':
                        return 'red';
                    default:
                        return 'primary';
                }
            },
            formatDate(value: moment.MomentInput) {
                return moment(value).tz('gmt').format('MMMM DD YYYY (hh:mm A)');
            },
            customSort: function (items: any[], index: number, isDesc: any[]) {
                items.sort((a, b) => {
                    if (
                        items[0] === 'created_date' ||
                        items[0] === 'scheduled_date'
                    ) {
                        if (!isDesc[0]) {
                            return (
                                new Date(b[index]).valueOf() -
                                new Date(a[index]).valueOf()
                            );
                        }

                        return (
                            new Date(a[index]).valueOf() -
                            new Date(b[index]).valueOf()
                        );
                    }

                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index]
                                .toLowerCase()
                                .localeCompare(b[index].toLowerCase());
                        }

                        return b[index]
                            .toLowerCase()
                            .localeCompare(a[index].toLowerCase());
                    }

                    return false;
                });

                return items;
            },
            lookupStatus(status: mhRequestStatus) {
                if (status in mhRequestStatusString) {
                    return mhRequestStatusString[status];
                } else {
                    return 'UNKNOWN';
                }
            }
        }
    });
