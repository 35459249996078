
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import Vue from 'vue';
    import { mhRequestStatus } from '@/constants';
    export default Vue.extend({
        name: 'RequestDetails',
        components: {},
        data() {
            return {
                newnotes: ''
            };
        },
        computed: {
            ...mapGetters({
                activeRequest: 'mhrequests/activeRequest'
            }),
            environmentLink(): string {
                return "/#/env/" + this.activeRequest.payload_json.dnsName
            },
            isCompleted(): boolean {
                return (
                    this.activeRequest.status ===
                    mhRequestStatus.COMPLETED
                );
            }
        },
        created() {
            this.$store.dispatch('mhrequests/getAllRequests').then(() => {
                this.$store.dispatch(
                    'mhrequests/getActiveRequest',
                    this.$route.params.id
                );
            });
        },
        methods: {
            markComplete() {
                this.activeRequest.status = mhRequestStatus.COMPLETED;
                this.newnotes =
                    moment().toISOString() +
                    ' - Task completed by ' +
                    localStorage.getItem('user');
                this.addNotes();
            },
            addNotes() {
                const newNoteString =
                    moment().toISOString() +
                    ' - ' +
                    localStorage.getItem('user') +
                    ' - ' +
                    this.newnotes;

                this.newnotes = '';
                this.activeRequest.log += '\r\n' + newNoteString;
                /* eslint-disable one-var */
                const payload = {
                    id: this.activeRequest.id,
                    status: this.activeRequest.status,
                    notes: this.activeRequest.log
                };

                this.$store.dispatch('mhrequests/updateRequest', payload);
                this.$store.dispatch(
                    'mhrequests/getActiveRequest',
                    this.$route.params.id
                );
            }
        }
    });
