export type Environment = {
    envId: number;
    kibana?: string;
    grafana?: string;
    populated?: boolean;
    tenants?: string;
    usageMetrics?: UserCounts;
    usageForTerm?: number;
    license?: LicenseDetails;
    company?: string;
    accountManager?: string;
    clientname?: string;
    dns: string;
    product?: string;
    envType: string;
    region?: string;
    version?: string;
    courses?: string;
    lastYearTotalUsers?: number;
    status: string;
    updateDt?: string;
    companyId?: string;
    companyName?: string;
    amName?: string;
    amId?: string;
    licenseId?: number;
    config?: EngineConfig;
    events?: EventRecord[];
    shouldCountRegs: boolean;
    internal_env: boolean;
    lastBuildDt?: string;
    lastDeployDt?: string;
    usageUpdateDt?: string;
};

export type Customer = {
    id: string;
    name: string;
    am_name: string;
    am_id: string;
    env_count: number;
    envs: Environment[];
};

export type UserCounts = {
    last_30_regular: number;
    last_30_dispatch: number;
    last_30_registration: number;
    last_90_regular: number;
    last_90_dispatch: number;
    last_90_registration: number;
    last_1_year_regular: number;
    last_1_year_dispatch: number;
    last_1_year_registration: number;
    update_dt: string;
};

export type AccountManager = {
    id: string;
    name: string;
};

export type EngineConfig = {
    [key: string]: number;
};

export type MhRequest = {
    id: string;
    status: string;
    url: string;
    payload_json: MhRequestPayload;
};

export type MhRequestPayload = {
    action: string;
    clientname: string;
    dnsname: string;
    newversion: string;
    product: string;
    notes: string;
};

export type MhRequestDetails = {
    [key: string]: number;
};

export type newEnvRecord = {
    envs: {
        envType: string;
        clientName: string;
        dnsName: string;
        internalEnv: boolean;
    }[];
    product: string;
    productId: number;
    awsRegion: string;
    clientId?: string;
    licenseId?: number;
};

export type AwsCosts = {
    year: string;
    cost: number;
    productId: string;
};

export type AwsServiceCost = {
    awsService: string;
    annualCost: number;
    year: string;
};

export type AwsServiceCostDbRecord = {
    aws_service: string;
    annual_cost: number;
    year: string;
};

export type AwsYearlyServiceCosts = {
    [year: string]: AwsServiceCost[];
};

export type AwsYearlyCustomerCosts = {
    [year: string]: AwsCustomerCost[];
};

export type AwsCustomerCost = {
    annualCost: number;
    company: string;
    customerId: string;
};

export type AwsCustomerCostDbRecord = {
    annual_cost: number;
    company: string;
    customer_id: string;
};

export class VersionNumber {
    product: 'engine' | 'content controller';
    majorVersion: number;
    minorVersion: number;
    version: string;

    constructor(version: string) {
        this.version = version;
        const pieces = version.split('.').map((v) => v.replace(/\D/g, ''));
        this.product = Number(pieces[0]) > 18 ? 'engine' : 'content controller';

        const majorVersionLength = Number(pieces[0]) >= 22 ? 1 : 2;
        this.majorVersion = Number(
            pieces.slice(0, majorVersionLength).join('.')
        );
        this.minorVersion = Number(pieces[majorVersionLength]);
    }

    public isGreaterThan(version: VersionNumber): boolean {
        if (this.majorVersion > version.majorVersion) {
            return true;
        } else if (
            this.majorVersion === version.majorVersion &&
            this.minorVersion > version.minorVersion
        ) {
            return true;
        }
        return false;
    }
}

export type ProductVersion = {
    version: string;
    release_date: string;
    approved_for_install: boolean;
};

export type ProductVersionLinks = {
    [key: string]: string;
};

export type TooltipItem = {
    xLabel: string | number;
    yLabel: string | number;
    datasetIndex: number;
    index: number;
};

export type CustomerColors = {
    [key: string]: string;
};

export type ReportingDataPoint = {
    annualCost: number;
    costSource: string;
    year: string;
};

export type YoyCostReport = {
    [year: string]: ReportingDataPoint[];
};

export type EventRecord = {
    action: string;
    dns: string;
    updateDt: string;
};

export type LicenseDetails = {
    start: string;
    termLength: number;
    usageMetric: string;
    usageLimit: number;
    active?: boolean;
    awsBudgetForTerm?: number;
    type: string;
    licenseId: number;
    clientId: string;
    expiration: string;
    envs: Environment[];
    product: string;
    productId: number;
    usageUpdateDt: string;
};

export type EnvCountType = 'LEARNERS' | 'REGS_CREATED' | 'REGS_UPDATED';
export type EnvCountParams = {
    dnsName: string;
    startDate: string; // 'YYYY-MM-DD'
    endDate: string; // 'YYYY-MM-DD'
    countType: EnvCountType;
};
export type EnvCountResults = {
    count: number;
};

export type CloudStackBoxCounts = {
    web: number;
    worker: number;
};

export type CloudStackBoxCountsWithLetter = {
    stackLetter: string;
    boxCounts: CloudStackBoxCounts;
};

export type CloudEnvironment = 'Prod' | 'Staging' | 'Stagingx';

export type CloudConfigSetting = {
    key: string;
    value: string;
};

export type CloudConfigMap = {
    [key in CloudEnvironment]: CloudConfigSetting[];
};

export type SnackbarEvent = 'unlocked' | 'saved' | 'success' | 'error';

export type SnackbarData = {
    event: SnackbarEvent,
    show: boolean,
    message: string,
    timeout: number,
    icon: string
};

export type HubSettings = {
    db_version: string;
    changelog: string;
    welcome_message: string;
    system_message: string;
    updated_dt: string;
};

export type FAQItem = {
    title: string;
    details: string;
    tags: string;       // comma-delimited
    create_dt: string;
    update_dt: string;
};

export type ChartJsDataset = {
    label: string,
    data: number[],
    backgroundColor: string
};

export type ChartData = {
    labels: string[],
    datasets: ChartJsDataset[]
};

export type DailyRegistrationData = {
    regdate: string,
    regcount: string
};

export type MonthlyRegistrationData = {
    [month: string]: number
};

export type YearlyRegistrationCounts = {
    [year: string]: DailyRegistrationData[]
};

export type YearlyRegistrationData = {
    [year: string]: MonthlyRegistrationData[],
};

export type AccountChange = {
    realm_id: string,
    realm_name: string,
    date: string,
    email: string,
    old_plan_type: string,
    new_plan_type: string
};

export type RealmType = 'little' | 'medium' | 'big' | 'bigger' | 'r60k';

export type RealmDetails = {
    realm_id: string,
    realm_name: string,
    create_date: string,
    account_type: string,
    parent_realm_name?: string,
    linked_children_realm_ids?: string
};

export type RealmOwner = {
    first_name: string,
    last_name: string,
    company: string,
    primary_email: string
};

export type RealmAccountEvent = {
    event_date: string,
    user_name: string,
    event_message: string
};

export type RealmApplication = {
    application_id: string,
    application_name: string
};

export type QueryPortalQuery = {
    name: string,
    func: (realmId: string, startDate: string, endDate: string) => Promise<any>,
    description: string
};