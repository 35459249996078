
    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';
    import Vue from 'vue';
    export default Vue.extend({
        props: {
            dnsName: {
                type: String,
                default: ''
            },
            clientName: {
                type: String,
                default: ''
            },
            product: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                showEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                dnsconfirm: '',
                notes: '',
                responseFromApi: null
            };
        },
        mixins: [utilities],
        computed: {
            confirmhint(): string {
                return 'Type DNS Name To Confirm Request: ' + this.dnsName;
            },
            shouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_SUPPORT) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            }
        },
        methods: {
            sendRebuildRequest() {
                this.sendRequest(Constants.requestAction.REBUILD);
            },
            sendRedeployRequest() {
                this.sendRequest(Constants.requestAction.REDEPLOY);
            },
            sendRequest(requestAction: any) {
                if (this.dnsconfirm === this.dnsName) {
                    let action;
                    switch (requestAction) {
                        case Constants.requestAction.REDEPLOY:
                            action = 'redeploy';
                            break;
                        case Constants.requestAction.REBUILD:
                            action = 'rebuild';
                            break;
                        default:
                            action = 'rebuild';
                            break;
                    }
                    const envDetails = {
                        payload: [
                            {
                                action: action,
                                clientName: this.clientName,
                                dnsName: this.dnsName,
                                product: this.product,
                                notes: action + ' Requested'
                            }
                        ],
                        requestedby: localStorage.getItem('user'),
                        envid: this.envid
                    };

                    this.$store
                        .dispatch('mhrequests/sendMhRequest', envDetails)
                        .then((response) => {
                            this.snackbarText = 'Your Request Has Been Created.';
                            this.snackbar = true;
                        })
                        .catch((error) => {
                            this.snackbarText = 'There was an error: ' + error;
                            this.snackbar = true;
                        });
                } else {
                    this.snackbarText = 'DNS Confirmation Failed.';
                    this.snackbar = true;
                    this.dnsconfirm = '';
                }
            }
        }
    });
