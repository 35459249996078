
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                headers: [
                    {
                        text: 'Action',
                        value: 'action'
                    },
                    {
                        text: 'Update Date',
                        value: 'updateDt'
                    }
                ],
                sortBy: 'updateDt',
                sortDesc: true,
                search: ''
            };
        },
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            })
        },
        methods: {
            formatDate(value: moment.MomentInput) {
                return moment(value).tz('gmt').format('MMMM DD YYYY (hh:mm A)');
            },
            customSort: function (items: any[], index: number, isDesc: any[]) {
                items.sort((a, b) => {
                    if (items[0] === 'updateDt') {
                        if (!isDesc[0]) {
                            return (
                                new Date(b[index]).valueOf() -
                                new Date(a[index]).valueOf()
                            );
                        }

                        return (
                            new Date(a[index]).valueOf() -
                            new Date(b[index]).valueOf()
                        );
                    }

                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index]
                                .toLowerCase()
                                .localeCompare(b[index].toLowerCase());
                        }

                        return b[index]
                            .toLowerCase()
                            .localeCompare(a[index].toLowerCase());
                    }

                    return false;
                });

                return items;
            }
        }
    });
