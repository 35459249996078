
    import { PropType, defineComponent } from 'vue';
    import Vue from 'vue';
    import manager from '@/lib/manager';
    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';
    import { CloudConfigMap, CloudEnvironment } from '@/store/types';
    import SettingsTableToolbar from '@/components/Cloud/SettingsTableToolbar.vue';
    import EditSettingModal from '@/components/Cloud/EditSettingModal.vue';

    export default defineComponent({
        name: 'SettingsTable',
        emits: ['unlocked', 'settingsUpdated'],
        props: {
            // currently selected environments
            envs: {
                type: Array as PropType<CloudEnvironment[]>,
                required: true
            }
        },
        created() {
            this.init();
        },
        data() {
            return {
                search: '',
                sortBy: 'settingKey',
                sortOrder: 'desc',
                canEdit:
                    utilities.methods.userIsInUserGroup(
                        Constants.cognitoGroupName.MH_ADMIN
                    ) ||
                    utilities.methods.userIsInUserGroup(
                        Constants.cognitoGroupName.CLOUD_ADMIN
                    ),
                modalMode: 'Closed',
                itemToDelete: '',
                hasMadeChanges: false,
                changedSettings: {} as any,
                envCfgs: {} as CloudConfigMap
            };
        },
        computed: {
            // construct table headers from selected environments
            headers() {
                const headersArr = [
                    {
                        text: 'Setting',
                        value: 'settingKey',
                        nowrap: true,
                        width: '25%',
                        key: 0
                    }
                ];
                this.envs.forEach((env) => {
                    headersArr.push({
                        text: env + ' Value',
                        value: env + '_value',
                        nowrap: false,
                        width: 'auto',
                        key: Constants.cloudEnvironments.indexOf(env) + 1
                    });
                });

                // maintain correct header sort order
                return headersArr.sort((a, b) => (a.key > b.key ? 1 : -1));
            },
            // ensure v-for loops maintain correct sort order
            sortedEnvs() {
                const ce = Constants.cloudEnvironments;
                return [...this.envs].sort(
                    (a, b) => ce.indexOf(a) - ce.indexOf(b)
                );
            },
            // merge selected env configs for table display
            dataArr() {
                if (!this.envCfgs[Constants.cloudEnvironments[0]]) {
                    return;
                }

                const cfgKeys = new Set<string>();

                // enumerate unique keys across all environments
                for (const env in this.envCfgs) {
                    this.envCfgs[env as CloudEnvironment].forEach((c) => {
                        cfgKeys.add(c.key);
                    });
                }

                const vals: any[] = [];

                // add key's value to table if env is currently selected
                cfgKeys.forEach((cfgKey) => {
                    let dataRow: any = { settingKey: cfgKey };

                    this.sortedEnvs.forEach((env) => {
                        const envCfg = this.envCfgs[env];
                        const matched = envCfg.find((c) => c.key === cfgKey);

                        //do not update value of cells that have been edited
                        const changes = this.changedSettings[env];
                        if (cfgKey in changes) {
                            dataRow[`${env}_value`] = matched
                                ? changes[cfgKey]
                                : '';
                        } else {
                            dataRow[`${env}_value`] = matched
                                ? matched.value
                                : '';
                        }
                    });
                    vals.push(dataRow);
                });

                return vals;
            }
        },
        methods: {
            // discard changes and load configs from API
            init() {
                Constants.cloudEnvironments.forEach((env) => {
                    manager.getCloudConfig(env).then((res) => {
                        Vue.set(this.envCfgs, env, res);
                        this.changedSettings[env] = {};
                    });
                });

                this.hasMadeChanges = false;
            },
            cancelClicked() {
                this.toggleLocked();
                this.init();
            },
            toggleLocked() {
                this.canEdit = !this.canEdit;
                if (this.canEdit) this.$emit('unlocked');
            },
            // pass create/delete/update setting events to parent
            propagateEvent(status: string) {
                this.$emit('settingsUpdated', status);
                if (status !== 'error') this.init();
            },
            // display Delete Setting modal
            promptToDelete(key: string) {
                this.modalMode = 'Delete';
                this.itemToDelete = key;
            },
            // track user changes to grid cells
            trackSettingChanges(
                env: CloudEnvironment,
                key: string,
                newVal: string
            ) {
                this.changedSettings[env as CloudEnvironment][key] = newVal;
                this.hasMadeChanges = true;
            },
            // write contents of changedSettings to database
            applySettingChanges() {
                const pending: Promise<any>[] = [];

                for (let [env, cfgs] of Object.entries(this.changedSettings)) {
                    for (let [key, val] of Object.entries(cfgs as any)) {
                        pending.push(
                            manager.updateCloudConfigSetting(
                                {
                                    key: key,
                                    value: val as string
                                },
                                env as CloudEnvironment
                            )
                        );
                    }
                }

                Promise.all(pending)
                    .then((res) => {
                        this.$emit('settingsUpdated', 'success');
                        this.init();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$emit('settingsUpdated', 'error');
                    });
            }
        },
        components: {
            EditSettingModal,
            SettingsTableToolbar
        }
    });
