/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:0afd0ffa-6d6d-4c9b-9f42-018daba497c9",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_TJKLVhKxy",
  aws_user_pools_web_client_id: "15pkeshf5paj0ah7e4e635letg",
  oauth: {
    domain:
      "dashboardvuecd7621f2-cd7621f2-prod.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://hub.rusticisoftware.com/",
    redirectSignOut: "https://hub.rusticisoftware.com/logout/",
    responseType: "code",
  },
  federationTarget: "JUMPCLOUD",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cloud_logic_custom: [
    {
      name: "mhapi",
      endpoint: "https://api.hub.rusticisoftware.com",
      region: "us-east-1",
    }
  ],
};

export default awsmobile;
