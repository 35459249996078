var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"flex-grow-1",attrs:{"headers":_vm.headers,"items":_vm.dataArr,"search":_vm.search,"sort-by":_vm.sortBy,"sort-order":_vm.sortOrder,"width":"100%"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortOrder":function($event){_vm.sortOrder=$event},"update:sort-order":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('EditSettingModal',{attrs:{"envs":_vm.envs,"mode":_vm.modalMode,"itemToDelete":_vm.itemToDelete},on:{"modalClosed":() => {
                    _vm.modalMode = 'Closed';
                },"deleteSetting":function($event){return _vm.propagateEvent($event)},"createSetting":function($event){return _vm.propagateEvent($event)}}}),_c('SettingsTableToolbar',{attrs:{"canEdit":_vm.canEdit,"canSave":_vm.hasMadeChanges},on:{"editClicked":_vm.toggleLocked,"saveClicked":_vm.applySettingChanges,"createClicked":() => (_vm.modalMode = 'Create'),"cancelClicked":_vm.cancelClicked,"searchUpdated":(newVal) => (_vm.search = newVal)}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-hover',{attrs:{"open-delay":"500","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('tr',[_c('td',{staticClass:"vertical-divider"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(item.settingKey))]),_c('v-btn',{class:{
                                'animated-button-reveal': hover && _vm.canEdit,
                                'animated-button-hide': !hover || !_vm.canEdit
                            },attrs:{"title":"Delete","disabled":!_vm.canEdit,"small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.promptToDelete(item.settingKey)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]),_vm._l((_vm.sortedEnvs),function(env){return _c('td',{key:env,staticClass:"px-1 vertical-divider",staticStyle:{"word-break":"break-all"}},[_c('v-textarea',{staticClass:"my-1",staticStyle:{"max-height":"200px","overflow-y":"auto","scrollbar-width":"thin"},attrs:{"solo":"","rows":"1","dense":"","readonly":!_vm.canEdit,"hide-details":"true","auto-grow":""},on:{"input":function($event){return _vm.trackSettingChanges(
                                env,
                                item.settingKey,
                                $event
                            )}},model:{value:(item[env + '_value']),callback:function ($$v) {_vm.$set(item, env + '_value', $$v)},expression:"item[env + '_value']"}})],1)})],2)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }