
    import { mapState, mapGetters } from 'vuex';
    import moment from 'moment';
    import Vue from 'vue';
    import FAQHome from '@/components/Home/FAQHome.vue';
    import ChangelogHome from '@/components/Home/ChangelogHome.vue';

    export default Vue.extend({
        name: 'HubHome',
        components: {
            FAQHome,
            ChangelogHome
        },
        data() {
            return { tab: 'Changelog' };
        },
        computed: {
            ...mapState({
                settings: (state: any) => state.settings
            }),
            ...mapGetters({
                hubSettings: 'settings/hubSettings'
            })
        }
    });
