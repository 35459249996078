
    import { mapGetters } from 'vuex';

    import Vue from 'vue';
    export default Vue.extend({
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            })
        }
    });
