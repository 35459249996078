const state = {
    regions: [
        'us-east-1',
        'eu-central-1',
        'eu-west-1',
        'ca-central-1',
        'us-east-2',
        'eu-west-2',
        'ap-southeast-2',
        'ap-southeast-1',
        'us-west-1',
        'us-west-2'
    ],
    envtypes: ['demo', 'dev', 'prod'],
    products: ['engine', 'contentcontroller']
};

export default {
    namespaced: true,
    state
};
