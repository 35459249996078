
    import manager from '@/lib/manager';
    import { PropType, defineComponent } from 'vue';
    import { CloudEnvironment } from '@/store/types';

    export default defineComponent({
        name: 'EnvironmentCard',
        props: {
            selected: {
                type: Boolean,
                required: true
            },
            env: {
                type: String as PropType<CloudEnvironment>,
                required: true
            }
        },
        data() {
            return {
                activeStack: '',
                boxCounts: { web: 0, worker: 0 }
            };
        },
        computed: {
            getClass() {
                return this.selected ? 'expanded-card' : 'collapsed-card';
            },
            getColor() {
                return this.selected ? 'teal' : 'default';
            }
        },
        async created() {
            this.activeStack = await manager.getCloudActiveStack();
            this.boxCounts = await manager.getBoxCountsForStack(
                this.activeStack,
                this.env
            );

            console.log(
                `Env ${this.env} returned ${this.boxCounts.web} web, ${this.boxCounts.worker} worker`
            );
        },
        emits: ['envSelected', 'envDeselected'],
        methods: {
            envClicked() {
                const eventType = this.selected
                    ? 'envDeselected'
                    : 'envSelected';
                this.$emit(eventType, this.env);
            }
        }
    });
