
    import Constants from '@/constants';
    import Vue, { set } from 'vue';
    import { mapState, mapGetters } from 'vuex';
    import utilities from '@/mixins/utilities';

    export default Vue.extend({
        props: {
            // eslint-disable-next-line vue/require-default-prop
            license: {
                type: Object,
                required: false,
            },
            customer: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                newEnvDialog: false,
                snackbar: false,
                devSelected: false,
                snackbarText: 'Your Request Has Been Created.',
                awsRegion: 'us-east-1',
                product: '',
                type: '',
                newEnvParams: [
                    {
                        dnsSubname: '',
                        dnsName: '',
                        clientName: '',
                        envType: ''
                    }
                ]
            };
        },
        mixins: [utilities],
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
            this.setLicenseProperties();
            this.populateSubnameAndClientName(this.type);
        },
        computed: {
            ...mapState({
                environments: (state: any) => state.environments.all,
                regions: (state: any) => state.aws.regions,
                envTypes: (state: any) => state.aws.envtypes,
                products: (state: any) => state.aws.products
            }),
            isCreateLicenseRequest(): boolean {
                return this.license && this.license.licenseId == 0
            },
            isProdEngineLicense(): boolean {
                return (
                    this.product === 'engine' &&
                    this.type === 'prod'
                );
            },
            shouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_SUPPORT) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            },
            subDomainValidation(): string | boolean {
                for (const env of this.newEnvParams) {
                    if (
                        env.envType === 'demo' &&
                        !env.dnsSubname.endsWith('-demo')
                    ) {
                        return "POCs must end with '-demo'";
                    }
                }
                return true;
            },
            clientNameValidation(): string | boolean {
                for (const env of this.newEnvParams) {
                    if (env.clientName.length > 13) {
                        return 'clientNames have a limit of 13 characters';
                    }
                }
                return true;
            }
        },
        methods: {
            setLicenseProperties() {
                if (this.license) {
                    this.product = this.license.product;
                    this.type = this.license.type;
                } else {
                    this.product = 'contentcontroller';
                    this.type = 'dev';
                }
            },
            submitForm() {
                if (this.hasErrors()) {
                    return
                }
                const licenseIds = this.license ? [this.license.licenseId] : []
                this.createNewEnvs(licenseIds);
            },
            createNewEnvs(licenseIds: any[]) {
                    const formData = this.getNewEnvData();

                        const newEnvRecord = {
                            envs: [] as any,
                            product: this.product,
                            productId: this.getProductId(),
                            awsRegion: this.awsRegion,
                            clientId: this.license ? this.license.clientId : null,
                            licenseIds: licenseIds
                        };

                        for (const params of formData.newEnvParams) {
                            const env = {
                                envType: params.envType,
                                clientName: params.clientName,
                                dnsName: params.dnsName,
                                internalEnv: this.license ? false : true
                            };
                            newEnvRecord.envs.push(env);
                        }

                        const commonPayload = {
                            action: 'newenv',
                            requested_by_slack: localStorage.getItem('slack'),
                            privatebranch: 'master',
                            appversion: 'latest',
                            product: this.product,
                            notes: '',
                            awsRegion: this.awsRegion
                        };

                        const envDetails = {
                            payload: [] as any,
                            requestedby: localStorage.getItem('user')
                        };

                        for (const data of formData.newEnvParams) {
                            const payload = {
                                ...commonPayload,
                                clientName: data.clientName,
                                dnsName: data.dnsName,
                                envType: data.envType
                            };
                            envDetails.payload.push(payload);
                        }

                        return this.$store
                            .dispatch(
                                'environments/createNewEnvRecord',
                                newEnvRecord
                            )
                            .then(() => {
                                this.$store.dispatch(
                                    'mhrequests/sendMhRequest',
                                    envDetails
                                );
                            })
                            .then(() => {
                                for (const data of formData.newEnvParams) {
                                    this.$store.dispatch('environments/getEnvByDns', data.dnsName);
                                }
                            })
                            .then(() => {
                                // This is being called to populate the customer details page with the newly created env.
                                // Ideally this should be moved into the editLicenseDetails component but I couldn't get the
                                // promise chain to flow as intended so just added it here :(
                                this.$store.dispatch(
                                    'customers/getCustomerLicenses',
                                    this.$route.params.id
                                );
                            })
                            .then(() => {
                                this.resetForm();
                            })
                            .catch((error) => {
                                this.snackbarText = error.message || 'There was an error: Unknown error';
                            });
                    },
            resetForm() {
                this.product = "contentcontroller"
                this.type = "dev"
                this.$emit('form-done', true);
            },
            getProductId() {
                switch (this.product) {
                    case 'engine':
                        return Constants.productId.ENGINE;
                    case 'contentcontroller':
                        return Constants.productId.CONTENT_CONTROLLER;
                    default:
                        return -1;
                }
            },
            populateSubnameAndClientName(envType: any) {
                this.newEnvParams = [
                    {
                        dnsSubname: this.calcDnsSubname(envType),
                        dnsName: this.calcDnsName(
                            this.calcDnsSubname(envType)
                        ),
                        clientName: this.calcClientName(envType),
                        envType: envType
                    }
                ];
                if (this.isProdEngineLicense && this.isCreateLicenseRequest || this.devSelected) {
                    this.newEnvParams.push({
                        dnsSubname: this.calcDnsSubname('dev'),
                        dnsName: this.calcDnsName(this.calcDnsSubname('dev')),
                        clientName: this.calcClientName('dev'),
                        envType: 'dev'
                    });
                }
            },
            updateDnsName(index: number) {
                // Update the dnsName property based on the new value of dnsSubname
                this.newEnvParams[index].dnsName = this.calcDnsName(this.newEnvParams[index].dnsSubname);
            },
            calcDnsSubname(envType: string): string {
                return this.customer != null ?
                    this.customer.name.replaceAll(' ', '').toLowerCase() +
                    '-' +
                    envType
                 : '-' + envType;
            },
            hasErrors(): boolean {
                for (const env of this.newEnvParams) {
                    if (
                        !this.isDnsNameAvailable(env.dnsSubname) ||
                        !this.isClientNameAvailable(env.clientName)
                    ) {
                        return true;
                    }
                }

                return false;
            },
            getNewEnvData(): any {
                let newEnvRecords = {
                    awsRegion: this.awsRegion,
                    newEnvParams: this.newEnvParams
                };
                return newEnvRecords;
            },
            isDnsNameAvailable(subname: string): boolean {
                let fqdnAvailable = true;

                if (subname !== '') {
                    for (const env of this.environments) {
                        if (env.dns === this.calcDnsName(subname)) {
                            fqdnAvailable = false;
                        }
                    }
                } else {
                    fqdnAvailable = false;
                }

                return fqdnAvailable;
            },
            isClientNameAvailable(clientName: string): boolean {
                let clientNameAvailable = true;
                if (clientName !== '' && clientName.length <= 13) {
                    for (const env of this.environments) {
                        if (env.clientname === clientName) {
                            clientNameAvailable = false;
                        }
                    }
                } else {
                    clientNameAvailable = false;
                }

                return clientNameAvailable;
            },
            calcDnsName(subname: string): string {
                let fqdn = '';

                if (this.product === 'engine') {
                    fqdn = subname + '.engine.scorm.com';
                }
                if (this.product === 'contentcontroller') {
                    fqdn = subname + '.contentcontroller.com';
                }

                return fqdn;
            },
            calcClientName(envType: string): string {
                let clientNameSuffix = '';

                switch (envType) {
                    case 'demo':
                        clientNameSuffix = 'dmo';
                        break;
                    case 'dev':
                        clientNameSuffix = 'dev';
                        break;
                    case 'prod':
                        clientNameSuffix = 'prd';
                        break;
                    default:
                        clientNameSuffix = 'unk';
                        break;
                }

                // eslint-disable-next-line no-magic-numbers
                return this.customer != null ?
                    this.customer.name
                        .replaceAll(' ', '')
                        .replaceAll('-', '')
                        .substr(0, 9)
                        .toLowerCase() + clientNameSuffix
                : clientNameSuffix;
            }
        },
    });
