import { CloudEnvironment } from "./store/types";

/**
 * Defines the Cognito group names.
 */
const cognitoGroupName = {
    MH_ADMIN: 'mh-admins',
    MH_SUPPORT: 'mh-support',
    MH_ACCOUNTMANAGER: 'mh-accountmanager',
    MH_USER: 'mh-users',
    CLOUD_SUPPORT: 'cloud-support',
    CLOUD_DEV: 'cloud-devs',
    CLOUD_ADMIN: 'cloud-admins'
};

/**
 * The length of default license terms for different environments.
 */
const licenseTermLength = {
    PROD: 12,
    DEMO: 3
};

const licenseUsageLimit = {
    USER: 30000,
    ENGINE_REGISTRATION: 300000,
    CC_REGISTRATION: 200000
};

/**
 * Represents the product IDs.
 * This is used in the MH Environments table.
 */
const productId = {
    ENGINE: 1,
    CONTENT_CONTROLLER: 2
};

/**
 * Represents the possible Cloud environments
 */
const cloudEnvironments: CloudEnvironment[] = [
    'Prod',
    'Staging',
    'Stagingx'
]

/**
 * Represents the status of an MH request.
 * This is used in the MH request table.
 */
export enum mhRequestStatus {
    NEW = 0,
    ACCEPTED = 1,
    AUTOMATED = 2,
    FAILED = 3,
    CANCELLED = 4,
    COMPLETED = 10
}

/**
 * Represents the status strings for MH requests.
 */
export enum mhRequestStatusString {
    'New Request' = 0,
    'Queued For Manual Task' = 1,
    'Automated' = 2,
    'Failed' = 3,
    'Cancelled' = 4,
    'Completed' = 10
}

/**
 * Represents the request actions.
 * This is used for the mh request forms.
 */
const requestAction = {
    REBUILD: 1,
    REDEPLOY: 2
};

/**
 * Represents the possible status states for an environment
 */
const environmentStatus = {
    RUNNING: 'running',
    STOPPED: 'stopped',
    PROCESSING: 'processing'
}

/**
 * Vuetify color palette to cycle through for chart.js. Adjacent elements
 * contrast with each other to aid readability.
 */
const chartColors = ["#2196F3", "#FF9800", "#4CAF50", "#00BCD4"];

/**
 * Ordered list of Cloud plan types from smallest to largest
 */
const cloudPlans = ['tester', 'linked', 'trial', 'trial_v2', 'little_v2',
                    'medium_v2', 'big_v2', 'bigger_v2', 'mega']

export default {
    cognitoGroupName,
    licenseTermLength,
    licenseUsageLimit,
    productId,
    requestAction,
    environmentStatus,
    cloudEnvironments,
    chartColors,
    cloudPlans
};
