
    import { defineComponent, set } from 'vue';
    import MarkdownEditor from '@/components/common/MarkdownEditor.vue';
    import Constants from '@/constants';
    import { Auth } from 'aws-amplify';
    import { mapGetters } from 'vuex';
    import { FAQItem } from '@/store/types';
    import { marked } from 'marked';
    import DOMPurify from 'dompurify';

    export default defineComponent({
        name: 'FAQHome',
        components: {
            MarkdownEditor
        },
        data() {
            return {
                search: '',
                editMode: 'create', // 'create' | 'update' | 'delete'
                userIsAdmin: false,
                editorUnlocked: false,
                deleteItem: {} as FAQItem,
                editItem: {} as FAQItem
            };
        },
        computed: {
            ...mapGetters({
                FAQData: 'faq/allItems'
            }),
            showModal() { return this.editMode === 'delete'; },
            getTitle() {
                if(this.editMode === 'update') return 'Edit FAQ Item';
                return 'Create New FAQ Item';
            }
        },
        methods: {
            handleChange(e: string) { this.editItem.details = e; },
            resetEditor() {
                this.editItem = { details: '' } as FAQItem;
                this.deleteItem = {} as FAQItem;
                this.editMode = 'create';
            },
            handleSave() {
                this.$store.dispatch(`faq/${this.editMode}Item`, this.editItem);
                this.resetEditor();
            },
            handleDelete(itm: FAQItem) {
                this.deleteItem = itm;
                this.editMode = 'delete';
            },
            handleDeleteConfirm() {
                this.$store.dispatch('faq/deleteItem', this.deleteItem);
                this.resetEditor();
            },
            handleEdit(itm: FAQItem) {
                this.editMode = 'update';
                this.editItem = itm;
            },
            handleCancel() {
                this.editorUnlocked = false;
                this.resetEditor();
            },

            // 'MH,General,DevOps' -> ['MH', 'General', 'DevOps']
            getTags(itm: FAQItem) {
                return itm.tags.split(',').map(tag => tag.trim());
            },

            // markdown -> sanitized HTML
            formatDetails(itm: FAQItem) {
                return DOMPurify.sanitize(marked(itm.details));
            },
        },
        async created(){
            this.$store.dispatch('faq/getAllItems');

            // only display editing tools if user is admin
            const user = await Auth.currentAuthenticatedUser();
            this.userIsAdmin = user.signInUserSession.accessToken.payload[
                'cognito:groups'
                ].includes(Constants.cognitoGroupName.MH_ADMIN);
        }
    });
