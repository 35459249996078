
    import { mapState, mapGetters } from 'vuex';
    import awsCostsChart from '@/components/HostingCosts/awsCostsChart.vue';
    import type { AwsCustomerCost, YoyCostReport } from '@/store/types';

    function mapCostToYoyReport(data: any[], sourceFieldName: string) {
        const chartData: YoyCostReport = {};
        data.forEach((element: any) => {
            if (!chartData[element.year]) {
                chartData[element.year] = [];
            }
            chartData[element.year].push({
                annualCost: element.annualCost,
                costSource: element[sourceFieldName],
                year: element.year
            });
        });
        return chartData;
    }
    import Vue from 'vue';
    export default Vue.extend({
        name: 'mh-cost-dashboard',
        components: {
            awsCostsChart
        },
        data() {
            return { loading: true };
        },
        computed: {
            ...mapState({
                yearlyCostDataByServiceForEngine: (state: any) =>
                    mapCostToYoyReport(
                        state.customers.yearlyCostDataByServiceForEngine,
                        'awsService'
                    ),
                yearlyCostDataByServiceForCc: (state: any) =>
                    mapCostToYoyReport(
                        state.customers.yearlyCostDataByServiceForCc,
                        'awsService'
                    ),
                yearlyCostDataByService: (state: any) =>
                    mapCostToYoyReport(
                        state.customers.yearlyCostDataByService,
                        'awsService'
                    ),
                yearlyCostDataByCustomer: (state: any) => {
                    const data = state.customers.yearlyCostDataByCustomer;
                    const yoyReport: YoyCostReport = {};
                    Object.keys(data).forEach((year) => {
                        yoyReport[year] = data[year].map(
                            (y: AwsCustomerCost) => ({
                                annualCost: y.annualCost,
                                costSource: y.company,
                                year
                            })
                        );
                    });
                    return yoyReport;
                }
            }),
            ...mapGetters({
                allCustomers: 'customers/allCustomers'
            })
        },
        created() {
            const promises: Promise<void>[] = [];
            promises.push(
                this.$store.dispatch(
                    'customers/getYearlyCostDataByServiceForEngine'
                )
            );
            promises.push(
                this.$store.dispatch(
                    'customers/getYearlyCostDataByServiceForCc'
                )
            );
            promises.push(
                this.$store.dispatch('customers/getYearlyAwsCostsByService')
            );
            this.$store.dispatch('customers/getCustomersAndAMs').then(() => {
                promises.push(
                    this.$store.dispatch(
                        'customers/getYearlyAwsCostsByCustomer',
                        '2021'
                    )
                );
                promises.push(
                    this.$store.dispatch(
                        'customers/getYearlyAwsCostsByCustomer',
                        '2022'
                    )
                );
                promises.push(
                    this.$store.dispatch(
                        'customers/getYearlyAwsCostsByCustomer',
                        '2023'
                    )
                );
                Promise.all(promises).then(() => {
                    this.loading = false;
                });
            });
        }
    });
