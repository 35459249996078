<script>
    import utilities from '@/mixins/utilities';
    import { Bar, mixins } from 'vue-chartjs';
    const { reactiveProp } = mixins;

    export default {
        extends: Bar,
        mixins: [reactiveProp],
        props: ['chartData', 'options'],
        data() {
            return {
                defaultOptions: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                // format axes and tooltips with commas
                                userCallback: function(value) {
                                        return value.toLocaleString();
                                    }
                            }
                        }]
                    }
                }
            }
        },
        mounted() {
            this.defaultOptions = utilities.methods.deepMerge(this.defaultOptions, this.options);
            this.renderChart(this.chartData, this.defaultOptions);
        }
    };
</script>
