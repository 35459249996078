
    import { mapState, mapGetters } from 'vuex';
    import utilities from '@/mixins/utilities';
    import { VersionNumber } from '@/store/types';
    import newEnvForm from '../MhRequestForms/newEnvForm.vue';


    import Vue from 'vue';

    export default Vue.extend({
        name: 'EnvList',
        mixins: [utilities],
        components: {
            newEnvForm
        },
        data() {
            return {
                filter: null,
                search: '',
                newEnvModal: false,
                headers: [
                    {
                        text: 'DNS',
                        align: 'start',
                        value: 'dns'
                    },
                    { text: 'ClientName', value: 'clientname' },
                    { text: 'Product', value: 'product' },
                    { text: 'Type', value: 'type' },
                    { text: 'Region', value: 'region' },
                    { text: 'Version', value: 'version' },
                    { text: 'Courses', value: 'courses' },
                    { text: 'Users', value: 'userCounts.last_1_year_regular' }
                ],
                internalEnvHeaders: [
                    {
                        text: 'DNS',
                        align: 'start',
                        value: 'dns'
                    },
                    { text: 'ClientName', value: 'clientname' },
                    { text: 'Product', value: 'product' },
                    { text: 'Type', value: 'type' },
                    { text: 'Region', value: 'region' },
                    { text: 'Version', value: 'version' },
                    { text: 'Courses', value: 'courses' }
                ],
                processingHeaders: [
                    {
                        text: 'DNS',
                        align: 'start',
                        value: 'dns'
                    },
                    { text: 'ClientName', value: 'clientname' },
                    { text: 'Product', value: 'product' },
                    { text: 'Type', value: 'type' },
                    { text: 'Status', value: 'status' }
                ]
            };
        },
        computed: {
            ...mapState({
                environments: (state: any) => state.environments
            }),
            ...mapGetters({
                engineEnvs: 'environments/engineEnvs',
                ccEnvs: 'environments/ccEnvs',
                activeEnvs: 'environments/activeEnvs',
                internalEnvs: 'environments/internalEnvs',
                processingEnvs: 'environments/processingEnvs',
                versions: 'versions/versions',
                latestReleaseOfMajorVersion:
                    'versions/latestReleaseOfMajorVersion'
            })
        },
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
            this.$store.dispatch('versions/getVersions', 'content controller');
            this.$store.dispatch('versions/getVersions', 'engine');
        },
        methods: {
            openNewEnvModal() {
                this.newEnvModal = true;
            },
            closeNewEnvModal() {
                this.newEnvModal = false;
            },
            handleClick(value: { dns: string }) {
                this.$router.push('/env/' + value.dns);
            },
            getColor(version: string) {
                const thisVersion = new VersionNumber(version);
                if (
                    this.latestReleaseOfMajorVersion(thisVersion).isGreaterThan(
                        thisVersion
                    )
                ) {
                    return 'orange';
                } else {
                    return 'green';
                }
            }
        }
    });
