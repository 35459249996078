var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v("Rustici Engine - "+_vm._s(_vm.activeVersion.version))]),_c('v-card-subtitle',[_vm._v("Release Date "+_vm._s(_vm.activeVersion.release_date))]),_c('v-card-text',[(_vm.activeVersion.approved_for_install == 1)?_c('v-card-actions',[_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" Approved For MH Installation ")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.changeApproval(false)}}},[_vm._v(" Disable Version for MH ")])],1):_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.changeApproval(true)}}},[_vm._v(" Enable Version for MH ")])],1),(_vm.activeLinks.productVersionLinks[_vm.engine_dists[0].dist])?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-8",attrs:{"height":"460"}},[_c('v-card-title',{staticClass:"justify-center mb-2 pb-0"},[_c('span',{staticClass:"text-center red--text"},[_vm._v("NET Download Links")])]),_vm._l((_vm.engine_dists.filter((e) => {
                                return e.dist.split('-')[0] === 'net';
                            })),function(dist){return _c('v-card-text',{key:dist.dist,staticClass:"text-center mb-0 pb-0"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"blue","text-color":"white"}},[_c('span',{staticClass:"inv-text"},[_vm._v(_vm._s(dist.dist)+" ("+_vm._s(dist.path_partial)+_vm._s(_vm.$route.params.version)+".zip)")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","light":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyDownloadLink(
                                                    _vm.activeLinks
                                                        .productVersionLinks[
                                                        dist.dist
                                                    ]['url']
                                                )}}},'span',attrs,false),on),[_vm._v("Copy")])]}}],null,true)})],1)],1)})],2)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-8",attrs:{"height":"460"}},[_c('v-card-title',{staticClass:"justify-center mb-2 pb-0"},[_c('span',{staticClass:"text-center red--text"},[_vm._v("JAVA Download Links")])]),_vm._l((_vm.engine_dists.filter((e) => {
                                return e.dist.split('-')[0] === 'java';
                            })),function(dist){return _c('v-card-text',{key:dist.dist,staticClass:"text-center mb-0 pb-0"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"#BF360C","text-color":"white"}},[_c('span',{staticClass:"inv-text"},[_vm._v(_vm._s(dist.dist)+" ("+_vm._s(dist.path_partial)+_vm._s(_vm.$route.params.version)+".zip)")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","light":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyDownloadLink(
                                                    _vm.activeLinks
                                                        .productVersionLinks[
                                                        dist.dist
                                                    ]['url']
                                                )}}},'span',attrs,false),on),[_vm._v("Copy")])]}}],null,true)})],1)],1)})],2)],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber","size":93,"width":12}}),_c('br'),_c('br'),_c('h2',[_vm._v("Loading Pre-Signed Links...")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }