
    import Vue from 'vue';
    import type { PropType } from 'vue';
    import chartJsWrapper from '@/components/common/chartJsWrapper.vue';
    import type { ReportingDataPoint, YoyCostReport } from '@/store/types';

    type TooltipItem = {
        xLabel: string | number;
        yLabel: string | number;
        datasetIndex: number;
        index: number;
    };
    type CustomerColors = {
        [key: string]: string;
    };
    function generateRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    function sort(a: ReportingDataPoint, b: ReportingDataPoint) {
        return a.costSource.localeCompare(b.costSource);
    }
    function randomColors(
        yearlyCosts: YoyCostReport,
        year: string
    ): CustomerColors {
        const customerColors: CustomerColors = {};
        const customerCosts: ReportingDataPoint[] = yearlyCosts[year];
        if (customerCosts) {
            customerCosts.forEach((customerCost) => {
                const { costSource } = customerCost;
                const color = generateRandomColor();
                customerColors[costSource] = color;
            });
        }
        return customerColors;
    }
    export default Vue.extend({
        name: 'awsCostsChart',
        components: {
            chartJsWrapper
        },
        props: {
            data: {
                type: Object as PropType<YoyCostReport>
            },
            chartTitle: {
                type: String
            },
            useDifferentColors: {
                type: Boolean
            }
        },
        data() {
            return {
                chartData: {},
                chartOptions: {}
            };
        },
        created() {
            this.chartOptions = this.buildChartOptions();
            this.chartData = this.buildChartData(this.data);
        },
        methods: {
            buildChartOptions() {
                return {
                    responsive: true,
                    indexAxis: 'y',
                    // Elements options apply to all of the options unless overridden in a dataset
                    // In this case, we are setting the border of each horizontal bar to be 2px wide
                    elements: {
                        bar: {
                            borderWidth: 2
                        }
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            label: (tooltipItem: TooltipItem) =>
                                `${tooltipItem.xLabel}: $${tooltipItem.yLabel}`
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    min: 0,
                                    callback: (val: number) => `$${val}`
                                }
                            }
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    maxRotation: 50,
                                    minRotation: 30,
                                    padding: 10,
                                    autoSkip: false,
                                    fontSize: 12
                                }
                            }
                        ]
                    },
                    legend: {
                        display: !this.useDifferentColors
                    }
                };
            },
            buildChartData(data: YoyCostReport) {
                const currentYear = new Date().getFullYear();
                const datasets = [];
                const colors = randomColors(data, String(currentYear));
                const backgroundColors = ['#FF00FF', '#FFDD33', '#009999']; // Add more colors as needed

                for (let year = currentYear; year >= 2021; year--) {
                    const yearLabel = `AWS Costs ${year}`;
                    const offset = currentYear - year;
                    const backgroundColorIndex =
                        offset > backgroundColors.length - 1
                            ? backgroundColors.length - 1
                            : offset;
                    const backgroundColor =
                        backgroundColors[backgroundColorIndex];
                    const sorted: ReportingDataPoint[] = (
                        data[year] || []
                    ).sort(sort);

                    datasets.push({
                        label: yearLabel,
                        data: sorted.map((item) => item.annualCost),
                        backgroundColor: this.useDifferentColors
                            ? sorted.map(
                                  (item) =>
                                      colors[
                                          item.costSource.replaceAll(' ', '_')
                                      ]
                              )
                            : backgroundColor,
                        order: year
                    });
                }
                return {
                    labels: (data[currentYear] || [])
                        .sort(sort)
                        .map((item: ReportingDataPoint) => item.costSource),
                    datasets
                };
            }
        }
    });
