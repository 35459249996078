import { Commit } from 'vuex';
import manager from '../../lib/manager';
import { MhRequest, MhRequestDetails } from '../types';

type State = {
    mhrequests: MhRequest[];
    initialized: boolean;
    active: string;
};

const state: State = {
        mhrequests: [],
        initialized: false,
        active: ''
    },
    getters = {
        getRequestById: (state: State) => (id: string) =>
            state.mhrequests.find(
                (mhrequest: MhRequest) => mhrequest.id === id
            ),
        activeRequest: (
            state: State,
            getters: { getRequestById: (id: string) => MhRequest }
        ) => getters.getRequestById(state.active)
    },
    actions = {
        getAllRequests({ commit }: { commit: Commit }) {
            if (!state.initialized) {
                return manager.getAllRequests().then((result: MhRequest[]) => {
                    commit('setAllRequests', result);
                    commit('setInitialized');
                });
            } else {
                return Promise.resolve();
            }
        },
        getActiveRequest({ commit }: { commit: Commit }, id: string) {
            const mhrequest = state.mhrequests.find(
                (mhrequest) => String(mhrequest.id) === id
            );
            if (typeof mhrequest !== 'undefined') {
                commit('setActiveRequest', mhrequest.id);
            }
        },
        sendMhRequest({}: { commit: Commit }, envDetails: MhRequestDetails) {
            return manager.sendMhRequest(envDetails);
        },
        updateRequest(
            {}: { commit: Commit },
            payload: { id: string; status: string; notes: string }
        ) {
            return manager.updateRequest(
                payload.id,
                payload.status,
                payload.notes
            );
        }
    },
    mutations = {
        setAllRequests(state: State, mhrequests: MhRequest[]) {
            state.mhrequests = mhrequests;
        },

        setInitialized(state: State, initialized: boolean) {
            state.initialized = initialized;
        },

        setActiveRequest(state: State, id: string) {
            state.active = id;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
