
    import { defineComponent } from 'vue';
    import type {
        Environment,
        LicenseDetails,
        UserCounts
    } from '@/store/types';
    import utilities from '@/mixins/utilities';

    export default defineComponent({
        name: 'UserCounts',
        props: {
            envId: {
                type: Number,
                required: true
            },
            licenseId: {
                type: Number,
                required: true
            }
        },
        mixins: [utilities],
        data() {
            return {
                headers: [
                    {
                        text: 'Term',
                        value: 'term',
                        variant: 'active'
                    },
                    {
                        text: 'Regular',
                        value: 'regular'
                    },
                    {
                        text: 'Dispatch',
                        value: 'dispatch'
                    },
                    {
                        text: 'Total',
                        value: 'total'
                    }
                ]
            };
        },
        computed: {
            license(): LicenseDetails {
                return this.$store.getters['customers/getLicenseById'](
                    this.licenseId
                );
            },
            activeEnv(): Environment {
                return this.$store.getters[
                    'customers/getEnvByEnvIdAndLicenseId'
                ](this.envId, this.licenseId);
            },
            dynamicHeaders() {
                if (
                    this.activeEnv &&
                    this.license &&
                    this.license.usageMetric === 'users'
                ) {
                    return this.headers;
                } else if (
                    this.activeEnv &&
                    this.license &&
                    this.license.usageMetric === 'registrations'
                ) {
                    return this.headers.filter(
                        (header) =>
                            header.value === 'term' || header.value === 'total'
                    );
                } else {
                    return [];
                }
            },
            items() {
                if (this.activeEnv?.usageMetrics) {
                    return [
                        this.userCountItem(
                            'Last 30 Days',
                            'last_30',
                            this.activeEnv.usageMetrics,
                            this.license.usageMetric
                        ),
                        this.userCountItem(
                            'Last 90 Days',
                            'last_90',
                            this.activeEnv.usageMetrics,
                            this.license.usageMetric
                        ),
                        this.userCountItem(
                            'Last 12 Months',
                            'last_1_year',
                            this.activeEnv.usageMetrics,
                            this.license.usageMetric
                        )
                    ];
                } else {
                    return [];
                }
            }
        },
        methods: {
            userCountItem(
                name: string,
                rangeTerm: 'last_30' | 'last_90' | 'last_1_year',
                userCounts: UserCounts,
                metricType: string | undefined
            ) {
                const item: any = {
                    term: name
                };

                if (metricType === 'users') {
                    item.regular = this.valueOrZero(
                        userCounts[`${rangeTerm}_regular`]
                    );
                    item.dispatch = this.valueOrZero(
                        userCounts[`${rangeTerm}_dispatch`]
                    );
                    item.total = item.regular + item.dispatch;
                } else if (metricType === 'registrations') {
                    item.total = this.valueOrZero(
                        userCounts[`${rangeTerm}_registration`]
                    );
                }

                return item;
            }
        }
    });
