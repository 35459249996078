
    import { PropType, defineComponent } from 'vue';
    import manager from '@/lib/manager';
    import Constants from '@/constants';
    import { CloudEnvironment } from '@/store/types';

    export default defineComponent({
        name: 'EditSettingModal',
        emits: ['deleteSetting', 'createSetting', 'modalClosed'],
        props: {
            mode: {
                type: String,
                required: true
            },
            envs: {
                type: Array as PropType<CloudEnvironment[]>,
                required: true
            },
            itemToDelete: {
                type: String,
                required: true
            }
        },
        computed: {
            isOpen() {
                return this.mode != 'Closed';
            },
            getColor() {
                return this.mode == 'Create' ? 'primary' : 'error'
            },
            title() {
                return this.mode == 'Create' ? 'Create New Setting' : 'Delete Setting';
            }
        },
        // TODO: why are grandchild props not reactive?
        watch: {
            mode() { this.targetEnvs = this.envs; }
        },
        data() {
            return {
                targetEnvs: this.envs,
                allEnvs: Constants.cloudEnvironments as CloudEnvironment[],
                newKey: '',
                newVal: ''
            };
        },
        methods: {
            executeAction() {
                this.mode == 'Create' ? this.createSetting() : this.deleteSetting();
            },
            closeModal() {
                this.targetEnvs = this.envs;
                this.newKey = '';
                this.newVal = '';

                this.$emit('modalClosed');
            },
            async deleteSetting() {
                const pending: Promise<any>[] = [];

                this.allEnvs.forEach(env => {
                    pending.push(manager.deleteCloudConfigSetting(this.itemToDelete, env));
                });

                Promise.all(pending).then(res => {
                    this.$emit('deleteSetting', 'success');
                    this.closeModal();
                }).catch(err => {
                    console.error(err);
                    this.$emit('deleteSetting', 'error');
                });
            },
            async createSetting() {
                const pending: Promise<any>[] = [];

                this.allEnvs.forEach(env => {
                    // assign empty value for non-selected environments
                    let newSetting = {
                        key: this.newKey,
                        value: this.targetEnvs.includes(env) ? this.newVal : ''
                    };

                    pending.push(manager.addCloudConfigSetting(newSetting, env));
                });

                Promise.all(pending).then(res => {
                    this.$emit('createSetting', 'success');
                    this.closeModal();
                }).catch(err => {
                    console.error(err);
                    this.$emit('createSetting', 'error');
                });
            }
        }
    });
