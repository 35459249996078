
    import { mapGetters } from 'vuex';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'ProductVersionDetails',
        data() {
            return {
                engine_dists: [
                    {
                        dist: 'java-engine',
                        path_partial: 'RusticiEngine_java_engine_'
                    },
                    {
                        dist: 'java-engine-dispatch',
                        path_partial: 'RusticiEngine_java_engine-dispatch_'
                    },
                    {
                        dist: 'java-dispatch',
                        path_partial: 'RusticiEngine_java_dispatch_'
                    },
                    {
                        dist: 'java-lrs',
                        path_partial: 'RusticiEngine_java_lrs_'
                    },
                    {
                        dist: 'net-engine',
                        path_partial: 'RusticiEngine_net_engine_'
                    },
                    {
                        dist: 'net-engine-dispatch',
                        path_partial: 'RusticiEngine_net_engine-dispatch_'
                    },
                    {
                        dist: 'net-dispatch',
                        path_partial: 'RusticiEngine_net_dispatch_'
                    },
                    {
                        dist: 'net-lrs',
                        path_partial: 'RusticiEngine_net_lrs_'
                    }
                ]
            };
        },
        components: {},
        computed: {
            ...mapGetters({
                activeVersion: 'versions/activeVersion',
                activeLinks: 'versions/activeLinks'
            })
        },
        created() {
            this.$store.dispatch('versions/getVersions', 'engine').then(() => {
                this.$store.dispatch(
                    'versions/getActiveVersion',
                    this.$route.params.version
                );
                this.$store.dispatch(
                    'versions/getVersionLinks',
                    this.$route.params.version
                );
            });
        },
        methods: {
            async copyDownloadLink(link: string) {
                await navigator.clipboard.writeText(link);
            },
            changeApproval(value: boolean) {
                this.activeVersion.approved_for_install = value;
                this.$store.dispatch(
                    'versions/updateVersion',
                    this.activeVersion
                );
            }
        }
    });
