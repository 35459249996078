
    export default {
        name: 'CognitoAuth',
        props: {
            code: {
                type: String,
                default: ''
            }
        }
    };
