
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'SettingsTableToolbar',
        emits: [
            'editClicked',
            'createClicked',
            'saveClicked',
            'cancelClicked',
            'searchUpdated'
        ],
        props: {
            canEdit: {
                type: Boolean,
                required: true
            },
            canSave: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            cancelAction() { return this.canSave ? 'Cancel' : 'Lock'; }
        },
        data() {
            return { search: '' };
        }
    });
