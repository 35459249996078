import { Commit } from 'vuex';
import manager from '../../lib/manager';
import { ProductVersion, ProductVersionLinks, VersionNumber } from '../types';

type State = {
    activeLinks: {
        product: 'content controller' | 'engine';
        productVersion: string;
        productVersionLinks: ProductVersionLinks;
    };
    versions: {
        cc: ProductVersion[];
        engine: ProductVersion[];
    };
    active: string;
    driverLink: string;
    rxdLink: string;
};

const latestReleases = new Map<string, VersionNumber>();

const state: State = {
        versions: {
            cc: [],
            engine: []
        },
        active: '',
        activeLinks: {
            product: 'engine',
            productVersion: '0.0.0',
            productVersionLinks: {}
        },
        driverLink: '',
        rxdLink: ''
    },
    getters = {
        versions:
            (state: State) => (product: 'content controller' | 'engine') => {
                const productKey = product == 'engine' ? 'engine' : 'cc';
                return state.versions[productKey];
            },
        getVersion: (state: State) => (version: string) =>
            state.versions.engine.find(
                (product_version: ProductVersion) =>
                    product_version.version === version
            ),
        latestReleaseOfMajorVersion: (state: State) => (version: VersionNumber) => {
            let versions = [] as ProductVersion[];

            if (!latestReleases.has(version.majorVersion.toString())) {
                console.log("Doing latest release stuff")
                versions = versions.concat(state.versions.engine, state.versions.cc);

                let maxVersion: VersionNumber | undefined = undefined;
                versions.forEach((v: ProductVersion) => {
                    const versionNumber = new VersionNumber(v.version);
                    if (versionNumber.majorVersion === version.majorVersion) {
                        if (!maxVersion || versionNumber.isGreaterThan(maxVersion)) {
                            maxVersion = versionNumber;
                        }
                    }
                });

                if (maxVersion == undefined) {
                    console.warn("Could not determine a major version for " + version.version);
                    maxVersion = new VersionNumber("9999.99.99");
                }

                latestReleases.set(version.majorVersion.toString(), maxVersion)
            }

            return latestReleases.get(version.majorVersion.toString());
        },
        activeVersion: (
            state: State,
            getters: { getVersion: (version: string) => ProductVersion }
        ) => getters.getVersion(state.active),
        activeLinks: () => {
            return state.activeLinks;
        },
        driverLink: () => {
            return state.driverLink;
        },
        rxdLink: () => {
            return state.rxdLink;
        }
    },
    actions = {
        getVersions(
            { commit, state }: { commit: Commit; state: State },
            product: 'content controller' | 'engine'
        ) {
            const productKey = product == 'engine' ? 'engine' : 'cc';
            const productVersions = state.versions[productKey];

            if (productVersions === undefined || productVersions.length == 0) {
                manager
                    .getProductVersions(product)
                    .then((result: ProductVersion[]) => {
                        const payload = {
                            product: product,
                            productVersions: result
                        };
                        commit('setProductVersions', payload);
                    });
            } else {
                const payload = {
                    product: product,
                    productVersions: productVersions
                };
                commit('setProductVersions', payload);
            }
        },
        getActiveVersion(
            { commit }: { commit: Commit },
            version_string: string
        ) {
            const product_version = state.versions.engine.find(
                (version) => version.version === version_string
            );
            if (typeof product_version !== 'undefined') {
                commit('setActiveVersion', product_version.version);
            }
        },
        getVersionLinks(
            { commit, state }: { commit: Commit; state: State },
            version: string
        ) {
            const product = 'engine';
            manager
                .getProductVersionLinks(product, version)
                .then((result: ProductVersionLinks) => {
                    const payload = {
                        product: product,
                        version: version,
                        productVersionLinks: result
                    };
                    commit('setProductVersionLinks', payload);
                });
        },
        updateVersion({}: { commit: Commit }, version: ProductVersion) {
            return manager.updateProductVersion(version);
        },
        getDriverLinks({ commit }: { commit: Commit }, product: string) {
            manager.getDriverLinks(product).then((result: string) => {
                const payload = {
                    product: product,
                    link: result
                };
                commit('setDriverLinks', payload);
            });
        }
    },
    mutations = {
        setProductVersions(
            state: State,
            payload: {
                product: 'content controller' | 'engine';
                productVersions: ProductVersion[];
            }
        ) {
            const productKey = payload.product == 'engine' ? 'engine' : 'cc';

            state.versions[productKey] = payload.productVersions;
        },
        setActiveVersion(state: State, version: string) {
            state.active = version;
        },
        setProductVersionLinks(
            state: State,
            payload: {
                product: 'content controller' | 'engine';
                productVersion: string;
                productVersionLinks: ProductVersionLinks;
            }
        ) {
            state.activeLinks = payload;
        },
        setDriverLinks(
            state: State,
            payload: { product: string; link: string }
        ) {
            if (payload.product == 'driver') {
                state.driverLink = payload.link;
            } else {
                state.rxdLink = payload.link;
            }
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
