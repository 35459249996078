import Vue from 'vue';
import { Commit } from 'vuex';
import manager from '../../lib/manager';
import type {
    Environment,
    EngineConfig,
    EventRecord,
    newEnvRecord,
    EnvCountParams,
    EnvCountResults
} from '../types';

type State = {
    all: Environment[];
    active: string;
    fields: object[];
    sortBy: string;
    initialized: boolean;
    /**
     * Store the user count results in an array.
     * That way, we can cache the results as the user navigates between environments, count types, and date ranges.
     * (I would have preferred to use a dict for thise, but reactivity can't notice when new properties are added)
     **/
    userCounts: { key: string; result: EnvCountResults }[];
};

const userCountParamsKey = (
    dnsName: string,
    startDate: string,
    endDate: string,
    countType: string
) => {
    return `${dnsName}-${startDate}-${endDate}-${countType}`;
};

const state: State = {
        all: [] as Environment[],
        active: '',
        fields: [
            {
                key: 'dns',
                sortable: true,
                label: 'DNS'
            },
            {
                key: 'product',
                sortable: true,
                label: 'Product'
            },
            {
                key: 'type',
                sortable: true,
                label: 'Type'
            },
            {
                key: 'region',
                sortable: true,
                label: 'Region'
            },
            {
                key: 'version',
                sortable: true,
                label: 'Version'
            },
            {
                key: 'courses',
                sortable: true,
                label: 'Courses'
            },
            {
                key: 'lastYearTotalUsers',
                sortable: true,
                label: 'Users',
                thAttr: { title: 'Trailing 12 Months' }
            },
            {
                key: 'status',
                sortable: true,
                label: 'Status'
            },
            {
                key: 'updateDt',
                sortable: true,
                label: 'Updated'
            },
            {
                key: 'companyId',
                sortable: false,
                label: 'CompanyId'
            },
            {
                key: 'companyName',
                sortable: false,
                label: 'CompanyName'
            },
            {
                key: 'amName',
                sortable: false,
                label: 'AmName'
            },
            {
                key: 'amId',
                sortable: false,
                label: 'AmId'
            },
            {
                key: 'licenseId',
                sortable: false,
                label: 'LicenseId'
            }
        ],
        sortBy: 'DNS',
        initialized: false,
        userCounts: []
    },
    getters = {
        getEnvByDns: (state: State) => (dns: string) =>
            state.all.find((env: { dns: string }) => env.dns === dns),
        activeEnv: (
            state: State,
            getters: { getEnvByDns: (dns: string) => Environment }
        ) => getters.getEnvByDns(state.active),
        engineEnvs: (state: State) =>
            state.all.filter(
                (env: any) =>
                    env.status === 'running' && env.product === 'engine'
            ),
        ccEnvs: (state: State) =>
            state.all.filter(
                (env: any) =>
                    env.status === 'running' &&
                    env.product === 'content controller'
            ),
        activeEnvs: (state: State) =>
            state.all.filter(
                (env: any) => env.status === 'running' && !env.internalEnv
            ),
        inactiveEnvs: (state: State) =>
            state.all.filter((env: any) => env.status === 'stopped'),
        internalEnvs: (state: State) =>
            state.all.filter(
                (env: any) => env.status === 'running' && env.internalEnv
            ),
        processingEnvs: (state: State) =>
            state.all.filter(
                (env: any) =>
                    env.status !== 'running' && env.status !== 'stopped'
            ),
        getEnvsByCustomer: (state: State) => (companyId: string) =>
            state.all.filter((env: Environment) => env.companyId == companyId),
        userCountResults: (state: State) => (params: EnvCountParams) => {
            const key = userCountParamsKey(
                params.dnsName,
                params.startDate,
                params.endDate,
                params.countType
            );
            const counts = state.userCounts.filter(
                (count) => count.key === key
            );
            if (counts.length > 0) {
                return counts[0].result;
            } else {
                return undefined;
            }
        }
    },
    actions = {
        getAllEnvironments({ commit }: { commit: Commit }) {
            if (!state.initialized) {
                return manager
                    .getEnvList()
                    .then((environments: Array<Environment>) => {
                        commit('setEnvList', environments);
                        commit('setInitialized', true);
                    });
            } else {
                return Promise.resolve();
            }
        },

        getEnvByDns({ commit, getters }: { commit: Commit, state: State, getters: any }, dns: string) {
            const environment = getters.getEnvByDns(dns);
            // If the env is already in state, resolve the promise. Otherwise let's fetch it.
            if (environment) {
                return Promise.resolve();
            } else {
                return manager
                    .getEnvByDns(dns)
                    .then((environment: Environment) => {
                        commit('addEnvironment', environment);
                    });
            }
        },

        createNewEnvRecord({}: { commit: Commit }, envDetails: newEnvRecord) {
            return manager.createNewEnvRecord(envDetails);
        },

        getEnvironmentConfig({ commit }: { commit: Commit }, dns: string) {
            return manager.getEnvConfig(dns).then((config: EngineConfig) => {
                commit('addConfig', config);
            });
        },

        getEnvironmentEvents({ commit }: { commit: Commit }, dns: string) {
            return manager
                .getAllEventsForEnv(dns)
                .then((events: EventRecord[]) => {
                    commit('addEvents', events);
                });
        },

        executeUserCount(
            { commit }: { commit: Commit },
            params: EnvCountParams
        ) {
            return manager
                .executeUserCount(params)
                .then((result: EnvCountResults) => {
                    commit('saveUserCount', { params: params, result: result });
                });
        }
    },
    mutations = {
        setEnvList(state: State, list: Environment[]) {
            state.all = list;
        },

        addEnvironment(state: State, env: Environment) {
            state.all.push(env);
        },

        updateEnv(state: State, environment: Environment) {
            for (let i = 0; i < state.all.length; i++) {
                if (state.all[i].dns == environment.dns) {
                    Vue.set(state.all, i, environment);
                    return;
                }
            }

            state.all.push(environment);
        },

        addConfig(state: State, config: EngineConfig) {
            const env = state.all.find((env) => env.dns === state.active);
            if (typeof env !== 'undefined') {
                env.config = config;
                Vue.set(state.all, state.all.indexOf(env), env);
            }
        },

        addEvents(state: State, events: EventRecord[]) {
            const env = state.all.find((env) => env.dns === state.active);
            if (typeof env !== 'undefined') {
                env.events = events;
            }
        },

        setActiveEnv(state: { active: string }, dns: string) {
            state.active = dns;
        },

        setInitialized(state: { initialized: boolean }, initialized: boolean) {
            state.initialized = initialized;
        },

        saveUserCount(
            state: State,
            info: { params: EnvCountParams; result: EnvCountResults }
        ) {
            const key = userCountParamsKey(
                info.params.dnsName,
                info.params.startDate,
                info.params.endDate,
                info.params.countType
            );
            const counts = state.userCounts.filter(
                (count) => count.key === key
            );

            if (counts.length > 0) {
                // Remove any existing records for this parameter set
                const countIndex = state.userCounts.indexOf(counts[0]);
                state.userCounts.splice(countIndex, 1);
            }

            state.userCounts.push({ key: key, result: info.result });
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
