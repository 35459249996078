
    import moment from 'moment';
    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';
    import Vue from 'vue';
    import { mapGetters } from 'vuex';
    import { ProductVersion, VersionNumber } from '@/store/types';

    export default Vue.extend({
        props: {
            dnsName: {
                type: String,
                default: ''
            },
            clientName: {
                type: String,
                default: ''
            },
            currentversion: {
                type: String,
                default: ''
            },
            product: {
                type: String,
                default: ''
            },
            envid: {
                type: Number
            }
        },
        data() {
            return {
                upgEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                upgradeVersion: '',
                responseFromApi: null,
                /* eslint-disable no-magic-numbers */
                upgradedate: new Date().toISOString().slice(0, 10),
                upgradetime: new Date().toISOString().slice(11, 16),
                /* eslint-enable no-magic-numbers */
                currentstep: 1,
                showDatetime: false,
                shouldRunMigrationTool: false
            };
        },
        mixins: [utilities],
        created() {
            this.getProductVersions();
        },
        computed: {
            ...mapGetters({
                versions: 'versions/versions'
            }),
            availableVersions: {
                get(): string[] {
                    const productVersions = this.versions(this.product);

                    if (
                        productVersions === undefined ||
                        productVersions.length == 0
                    ) {
                        // We haven't fetched the list of versions yet.
                        return ['Fetching versions. Please wait...'];
                    }

                    const currentVersion = new VersionNumber(this.currentversion);
                    const validVersions = productVersions
                        .filter((version: ProductVersion) => this.product != 'engine' || version.approved_for_install)
                        .map((version: ProductVersion) => version.version)
                        .filter((version: string) => new VersionNumber(version).isGreaterThan(currentVersion))
                        .sort((a: string, b: string) => {
                            const versionA = new VersionNumber(a);
                            const versionB = new VersionNumber(b);
                            if (versionA.isGreaterThan(versionB)) {
                                return -1;
                            } else {
                                return 1;
                            }
                        });

                    return validVersions;
                }
            },
            computedUpgradeTime: {
                get(): string {
                    if (this.showDatetime) {
                        return moment(this.upgradedate + 'T' + this.upgradetime + 'Z').toISOString();
                    } else {
                        return new Date().toISOString();
                    }
                }
            },
            computedShouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_SUPPORT) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            }
        },
        methods: {
            getProductVersions() {
                this.$store.dispatch('versions/getVersions', this.product);
            },
            determineEngineMigrationTool() {
                const currentVersionPieces = this.currentversion.split('.');
                const newVersionPieces = this.upgradeVersion.split('.');
                const majorVersionLength = this.product === 'engine' ? 1 : 2;

                this.shouldRunMigrationTool = currentVersionPieces.slice(0, majorVersionLength).join('.') !== newVersionPieces.slice(0, majorVersionLength).join('.');
            },
            sendUpgradeEnvironmentRequest() {
                const envDetails = {
                    payload: [
                        {
                            action: 'upgrade',
                            clientName: this.clientName,
                            dnsName: this.dnsName,
                            newversion: this.upgradeVersion,
                            notes: '',
                            product: this.product,
                            runEngineMigrationTool: this.shouldRunMigrationTool
                        }
                    ],
                    requestedby: localStorage.getItem('user'),
                    scheduleddate: this.computedUpgradeTime,
                    envid: this.envid
                };

                this.$store
                    .dispatch('mhrequests/sendMhRequest', envDetails)
                    .then((response) => {
                        this.responseFromApi = response;
                        this.upgEnvDialog = false;
                        this.snackbar = true;
                        this.resetForm();
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.resetForm();
                this.upgEnvDialog = false;
            },
            resetForm() {
                this.upgradeVersion = '';
                // eslint-disable-next-line no-magic-numbers
                this.upgradedate = new Date().toISOString().slice(0, 10);
                this.upgradetime = '';
                this.currentstep = 1;
                this.shouldRunMigrationTool = false;
            }
        }
    });
