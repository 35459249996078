
import Vue from 'vue';
import {
    EnvCountType,
    EnvCountParams,
    EnvCountResults,
Environment
} from '@/store/types';
import utilities from '@/mixins/utilities';

const today = new Date();
const yearAgo = new Date(new Date().setFullYear(today.getFullYear() - 1));

export default Vue.extend({
    props: {
        dnsName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            startDate: utilities.methods.yearMonthDay(yearAgo),
            endDate: utilities.methods.yearMonthDay(today),

            countType: "LEARNERS" as EnvCountType,
            modEnvDialog: false,
            loading: false
        };
    },
    created() {
        this.$store.dispatch('environments/getEnvByDns', this.dnsName);
    },
    computed: {
        environment(): Environment {
            return this.$store.getters['environments/getEnvByDns'](this.dnsName)
        },
        shouldCountRegs(): boolean {
            return this.environment ? this.environment.shouldCountRegs : false
        },
        countTypeOptions() {
            const countTypeOptions = [
                { countType: "LEARNERS", name: "Learners" }
            ];

            if (this.environment.shouldCountRegs) {
                countTypeOptions.push(
                    { countType: "REGS_CREATED", name: "Registrations Created" },
                    { countType: "REGS_UPDATED", name: "Registrations Updated" }
                );
            }

            return countTypeOptions
        },
        userCountResults(): EnvCountResults {
            return this.$store.getters['environments/userCountResults'](this.userCountParams);
        },
        userCountParams(): EnvCountParams {
            return {
                dnsName: this.dnsName,
                startDate: this.startDate,
                endDate: this.endDate,
                countType: this.countType
            }
        },
        countTypeLabel(): string {
            if (this.countType === "LEARNERS") {
                return "learners";
            } else if (this.countType === "REGS_CREATED") {
                return "registrations created";
            } else if (this.countType === "REGS_UPDATED") {
                return "registrations updated";
            } else {
                throw new Error("Unknown count type: " + this.countType);
            }
        }
    },
    methods: {
        cancel() {
            this.modEnvDialog = false;
        },
        executeCount() {
            this.loading = true;
            this.$store.dispatch(
                'environments/executeUserCount',
                {
                    dnsName: this.dnsName,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    countType: this.countType
                }
            )
                .catch((ex) => {
                    console.error(ex);
                })
                .finally(() => {
                    this.loading = false
                });
        }
    }
});
