import { Commit } from 'vuex';
import manager from '../../lib/manager';
import type { FAQItem } from '../types';

type State = {
    items: FAQItem[];
};

const state: State = {
        items: [{
            title: '',
            details: '',
            tags: '',
            create_dt: '',
            update_dt: ''
        }]
    },
    getters = {
        allItems: (state: State) => {
            return state.items;
        }
    },
    actions = {
        async getAllItems({ commit }: { commit: Commit }) {
            const result = await manager.getFAQItems();
            commit('setAllItems', result);
        },
        async createItem({ commit }: { commit: Commit }, item: FAQItem) {
            return await manager.createFAQItem(item).then(() => {
                manager.getFAQItems().then((result: FAQItem[]) => {
                    commit('setAllItems', result);
                });
            });
        },
        async deleteItem({ commit }: { commit: Commit }, item: FAQItem) {
            return await manager.deleteFAQItem(item).then(() => {
                manager.getFAQItems().then((result: FAQItem[]) => {
                    commit('setAllItems', result);
                });
            });
        },
        async updateItem({ commit }: { commit: Commit }, item: FAQItem) {
            return await manager.updateFAQItem(item).then(() => {
                manager.getFAQItems().then((result: FAQItem[]) => {
                    commit('setAllItems', result);
                });
            });
        },
    },
    mutations = {
        setAllItems(state: State, result: FAQItem[]) {
            state.items = result;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
