
    import { defineComponent } from 'vue';
    import { mapState, mapGetters } from 'vuex';
    import { Auth } from 'aws-amplify';
    import { marked } from 'marked';
    import DOMPurify from 'dompurify';
    import moment from 'moment';
    import Constants from '@/constants';
    import MarkdownEditor from '@/components/common/MarkdownEditor.vue';
    import utilities from '@/mixins/utilities';

    export default defineComponent({
        name: 'ChangelogHome',
        components: { MarkdownEditor },
        computed: {
            ...mapState({
                settings: (state: any) => state.settings
            }),
            ...mapGetters({
                hubSettings: 'settings/hubSettings'
            }),
            title(): string {
                return this.isEditing ? 'Changelog (Editing)' : 'Changelog';
            },
            changelog(): string {
                return DOMPurify.sanitize(marked(this.hubSettings.changelog));
            }
        },
        data() {
            return {
                userIsAdmin: utilities.methods.userIsInUserGroup(
                    Constants.cognitoGroupName.MH_ADMIN
                ),
                isEditing: false
            };
        },
        methods: {
            formatDate(value: moment.MomentInput) {
                return moment(value).format('MMMM DD YYYY');
            },
            handleChange(newVal: string) {
                this.hubSettings.changelog = newVal;
            },
            handleCancel() {
                this.$store.dispatch('settings/getHubSettings');
                this.isEditing = false;
            },
            handleSave() {
                this.$store.dispatch(
                    'settings/updateHubSettings',
                    this.hubSettings
                );

                this.isEditing = false;
            }
        }
    });
