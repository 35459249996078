import moment from 'moment-timezone';

export default {
    methods: {
        relativeDate(date: moment.MomentInput): string {
            return moment.utc(date).local().fromNow();
        },
        localTime(date: moment.MomentInput): moment.Moment {
            return moment.utc(date).local();
        },
        valueOrUnknown(value: any): any {
            return value === null ? 'Unknown' : value;
        },
        valueOrZero(value: any): any {
            return value === null ? 0 : value;
        },
        yearMonthDay(datetime: moment.MomentInput): string {
            return moment(datetime).format('YYYY-MM-DD');
        },
        capitalizeFirstLetter(str: string): string {
            return (str.charAt(0).toUpperCase() + str.slice(1)) as string;
        },
        userIsInUserGroup(group: string): boolean {
            const userGroups = localStorage.getItem('user-groups');
            if (!userGroups) {
                return false;
            }

            const groupsArray = JSON.parse(userGroups);
            return groupsArray.includes(group);
        },
        getTypeFromKeyAndValue(key: string, value: string | number): string {
            if (!isNaN(Number(value))) {
                return 'number';
            } else if (String(value).startsWith('[')) {
                return 'list';
            } else if (String(value).startsWith('{')) {
                return 'object';
            } else if (key.startsWith('database')) {
                return 'incompleteObject';
            } else {
                return 'string';
            }
        },
        checkStringNotEmptyAndUsesWordCharacters(value: string): boolean | string {
            const regex = /^\w+$/g;

            if (value === '') return 'Key cannot be empty';

            return regex.test(value)
                ? true
                : 'String values must only contain the following characters: [A-Za-z0-9_]';
        },
        // merge passed options and default options
        deepMerge(tar: any, src: any) {
            const output = Object.assign({}, tar);

            for (const key in src) {
                if (src.hasOwnProperty(key)) {
                    if (typeof src[key] === 'object') {
                        if (!(key in tar)) {
                            Object.assign(output, { [key]: src[key] });
                        } else {
                            output[key] = this.deepMerge(tar[key], src[key]);
                        }
                    } else {
                        Object.assign(output, { [key]: src[key] });
                    }
                }
            }
            return output;
        }
    }
};
