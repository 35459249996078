
    import { mapGetters } from 'vuex';

    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';

    import engineConfig from './engineConfig.vue';
    import ccConfig from './ccConfig.vue';
    import envEvents from './envEvents.vue';
    import upgradeEnvForm from '../MhRequestForms/upgradeEnvForm.vue';
    import deleteEnvForm from '../MhRequestForms/deleteEnvForm.vue';
    import genericEnvModForm from '../MhRequestForms/genericEnvModForm.vue';
    import updateEnvForm from '../MhRequestForms/updateEnvForm.vue';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'EnvDetails',
        components: {
            engineConfig,
            ccConfig,
            envEvents,
            upgradeEnvForm,
            deleteEnvForm,
            genericEnvModForm,
            updateEnvForm
        },
        mixins: [utilities],
        data() {
            return {
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                menu: false
            };
        },
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            }),
            awsRdsLink: {
                get() {
                    return (
                        'https://' +
                        this.activeEnv.region +
                        '.console.aws.amazon.com/rds/home?region=' +
                        this.activeEnv.region +
                        '#database:id=mh-' +
                        this.activeEnv.clientname +
                        '-rds-masterdb;is-cluster=false'
                    );
                }
            },
            awsElbLink: {
                get() {
                    return (
                        'https://' +
                        this.activeEnv.region +
                        '.console.aws.amazon.com/ec2/v2/home?region=' +
                        this.activeEnv.region +
                        '#LoadBalancers:search=' +
                        this.activeEnv.clientname +
                        ';sort=loadBalancerName'
                    );
                }
            },
            awsS3Link: {
                get() {
                    return (
                        'https://s3.console.aws.amazon.com/s3/buckets/' +
                        this.activeEnv.clientname +
                        '-cc-content-logs?region=' +
                        this.activeEnv.region +
                        '&tab=objects'
                    );
                }
            },
            githubAnsibleChangeLog: {
                get() {
                    return (
                        'https://github.com/RusticiSoftware/cc-deployment-private/commits/master/host_vars/' +
                        this.activeEnv.dns +
                        '.yml'
                    );
                }
            },
            githubTerraformChangeLog: {
                get() {
                    return (
                        'https://github.com/RusticiSoftware/cc-deployment-private/commits/master/terraform/' +
                        this.activeEnv.dns +
                        '/terraform.tfvars.json'
                    );
                }
            },
            lokiExploreTabEngine: {
                get() {
                    return (
                        'https://grafana.rusticisoftware.com/explore?panes=%7B%22MWn%22:%7B%22datasource%22:%22babe7552-f6d4-4d8a-aacb-0beae877df49%22,%22queries%22:%5B%7B%22datasource%22:%7B%22type%22:%22loki%22,%22uid%22:%22babe7552-f6d4-4d8a-aacb-0beae877df49%22%7D,%22editorMode%22:%22builder%22,%22expr%22:%22%7Bclientname%3D%5C%22' +
                        this.activeEnv.clientname +
                        '%5C%22,%20filename%3D~%5C%22%2Fusr%2Fshare%2Ftomcat%2Flogs%2Fscormengine.log%5C%22%7D%20%7C%20logfmt%22,%22queryType%22:%22range%22,%22refId%22:%22A%22%7D%5D,%22range%22:%7B%22from%22:%22now-5m%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1&orgId=1'
                    );
                }
            },
            lokiExploreTabCc: {
                get() {
                    return (
                        'https://grafana.rusticisoftware.com/explore?panes=%7B%22MWn%22:%7B%22datasource%22:%22babe7552-f6d4-4d8a-aacb-0beae877df49%22,%22queries%22:%5B%7B%22datasource%22:%7B%22type%22:%22loki%22,%22uid%22:%22babe7552-f6d4-4d8a-aacb-0beae877df49%22%7D,%22editorMode%22:%22builder%22,%22expr%22:%22%7Bclientname%3D%5C%22' +
                        this.activeEnv.clientname +
                        '%5C%22,%20filename%3D~%5C%22%2Fvar%2Flog%2Fcontentcontroller.log%5C%22%7D%20%7C%20logfmt%22,%22queryType%22:%22range%22,%22refId%22:%22A%22%7D%5D,%22range%22:%7B%22from%22:%22now-5m%22,%22to%22:%22now%22%7D%7D%7D&schemaVersion=1&orgId=1'
                    );
                }
            },
            isCc() {
                return (
                    this.activeEnv.product === 'content controller' ||
                    this.activeEnv.product === 'contentcontroller'
                );
            },
            statusColor() {
                if (
                    this.activeEnv.status ===
                    Constants.environmentStatus.PROCESSING
                ) {
                    return 'orange';
                } else if (
                    this.activeEnv.status ===
                    Constants.environmentStatus.RUNNING
                ) {
                    return 'green';
                } else {
                    return 'red';
                }
            }
        },
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
            this.$store.commit(
                'environments/setActiveEnv',
                this.$route.params.id
            );
            this.$store.dispatch(
                'environments/getEnvironmentConfig',
                this.$route.params.id
            );
            this.$store.dispatch(
                'environments/getEnvironmentEvents',
                this.$route.params.id
            );
        },
        methods: {
            customerLink(id: string) {
                this.$router.push('/customer/' + id);
            }
        }
    });
