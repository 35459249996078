
    import { mapGetters } from 'vuex';

    import utilities from '@/mixins/utilities';
    import moment from 'moment';
    import createCustomer from '@/components/MhCustomers/createCustomer.vue';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'CustomerList',
        components: { createCustomer },
        mixins: [utilities],
        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Account Manager', value: 'am_name' },
                    { text: 'Environments', value: 'env_count' },
                    { text: 'License Expiration', value: 'licenses' }
                ]
            };
        },
        computed: {
            ...mapGetters({
                customers: 'customers/allCustomers'
            })
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs');
        },
        methods: {
            getLicenseColor(expiration: string) {
                const expirationDate = new Date(expiration);
                const today = new Date();
                if (expirationDate < today) {
                    return 'red';
                }
                if (
                    expirationDate <
                    new Date(today.setDate(today.getDate() + 90))
                ) {
                    return 'orange';
                } else {
                    return 'green';
                }
            },
            handleClick(value: { id: string }): void {
                this.$router.push('/customer/' + value.id);
            },
            formatProductName(product: string) {
                if (product == "contentcontroller") {
                    return "CC";
                } else {
                    return utilities.methods.capitalizeFirstLetter(product);
                }
            }
        }
    });
