
    import { Amplify, Auth, Hub } from 'aws-amplify';
    import awsconfig from '../aws-exports';
    import Vue from 'vue';
    import Constants from '../constants';
    import utilities from '@/mixins/utilities';
    import { mapGetters, mapState } from 'vuex';

    const groups = Constants.cognitoGroupName;

    export default Vue.extend({
        name: 'App',
        data() {
            return {
                user: {},
                authState: '',
                drawer: false
            };
        },
        computed: {
            ...mapState({
                settings: (state: any) => state.settings
            }),
            ...mapGetters({
                hubSettings: 'settings/hubSettings'
            }),
            showCloudDashboard(): boolean {
                return [groups.MH_ADMIN, groups.CLOUD_ADMIN, groups.CLOUD_DEV]
                    .some(group => utilities.methods.userIsInUserGroup(group));
            }
        },
        mounted() {
            const theme = localStorage.getItem('darkTheme');

            // Check if the user has set the theme state before
            if (theme) {
                if (theme === 'true') {
                    this.$vuetify.theme.dark = true;
                } else {
                    this.$vuetify.theme.dark = false;
                }
            } else if (
                window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: dark)').matches
            ) {
                this.$vuetify.theme.dark = true;
                localStorage.setItem(
                    'darkTheme',
                    this.$vuetify.theme.dark.toString()
                );
            }
        },
        created() {
            Amplify.configure(awsconfig);

            this.$store.dispatch('settings/getHubSettings');

            Hub.listen('auth', ({ payload: { event, data } }) => {
                switch (event) {
                    case 'signIn':
                        console.log('sign in', event, data);
                        break;
                    case 'signOut':
                        console.log('sign out');
                        break;
                    default:
                        break;
                }
            });

            Auth.currentAuthenticatedUser()
                .then((user) => {
                    if (!user.username) {
                        console.log(
                            'Not Logged In... forcing federated sign in'
                        );
                        Auth.federatedSignIn({
                            customProvider: 'JumpCloud'
                        });
                    } else {
                        this.authState = 'signedin';
                        this.user = user;
                        // eslint-disable-next-line dot-notation
                        console.log(
                            'slack = @' +
                                user.signInUserSession.idToken.payload
                                    .preferred_username
                        );
                        localStorage.setItem(
                            'user-groups',
                            JSON.stringify(
                                user.signInUserSession.accessToken.payload[
                                    'cognito:groups'
                                ]
                            )
                        );
                        localStorage.setItem(
                            'user',
                            JSON.stringify(user.username)
                        );
                        localStorage.setItem(
                            'slack',
                            user.signInUserSession.idToken.payload
                                .preferred_username
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // redirect user to login page if not logged in
                    this.loginUser();
                });
        },
        methods: {
            loginUser() {
                Auth.federatedSignIn({
                    customProvider: 'JumpCloud'
                });
            },
            logoutUser() {
                localStorage.clear();
                Auth.signOut();
            },
            toggleDarkMode() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                localStorage.setItem(
                    'darkTheme',
                    this.$vuetify.theme.dark.toString()
                );
            },
            cleanUsername(username: string) {
                return username.replace('jumpcloud_', '');
            }
        }
    });
