
    import { marked } from 'marked';
    import { defineComponent } from 'vue';
    import DOMPurify from 'dompurify';

    export default defineComponent({
        name: 'MarkdownEditor',
        emits: ['updated'],
        props: {
            text: {
                type: String,
                required: false
            }
        },
        watch: {
            text(newVal: string) { this.value = newVal; }
        },
        data() {
            return {
                leftPaneSize: 50,
                rightPaneSize: 50,
                mouseDown: false,
                isDragging: false,
                debounceTimer: 0,
                value: this.text || `# Markdown is supported!
## Header 2
### Header 3

*italic text* or **bold text**

[link](https://rusticisoftware.com)

![img name](https://i.imgur.com/S9LhgcZ.png)

\`inline code\`

\`\`\`
large block of code with formatting
    spanning multiple lines
\`\`\`

[//]: <> (This is a comment and will not be displayed.)
[//]: <> (Create blank lines with <br/>:)
<br/>

- unordered list item
- another unordered list item

<br/>

1. ordered list item
1. another ordered list item
1. a third ordered list item

<br/>

- [ ] checklist item
- [x] completed checklist item
`
            };
        },
        computed: {
          compiledMarkdown() {
            return DOMPurify.sanitize(marked(this.value));
          }
        },
        methods: {
            // debounced to prevent event spam from fast typing
            update(e: string) {
                if(this.debounceTimer)  window.clearTimeout(this.debounceTimer);

                this.debounceTimer = window.setTimeout(() => {
                    this.value = e;
                    this.$emit('updated', this.value);
                }, 200);
            }
        },
        // split panes "drag to resize" logic
        mounted() {
            const el = document.getElementById('handle')!;
            el.addEventListener('mousedown', () => this.mouseDown = true);

            document.addEventListener('mouseup', () => this.mouseDown = false);
            document.addEventListener('mousemove', (e) => {
                this.isDragging = this.mouseDown;

                if(!this.isDragging)    return;

                const delta = e.movementX * 0.1;
                if(delta > 0 && this.leftPaneSize > 25) {
                    this.rightPaneSize += delta;
                    this.leftPaneSize -= delta;
                } else if(delta < 0 && this.rightPaneSize > 25) {
                    this.rightPaneSize -= Math.abs(delta);
                    this.leftPaneSize += Math.abs(delta);
                }
            });

        }
    });
