import { Commit } from 'vuex';
import manager from '@/lib/manager';
import { HubSettings } from '../types';

type State = {
    settings: HubSettings;
};

const state: State = {
        settings: {
            db_version: '',
            changelog: '',
            welcome_message: '',
            system_message: '',
            updated_dt: ''
        }
    },
    getters = {
        hubSettings: (state: State) => {
            return state.settings;
        }
    },
    actions = {
        async getHubSettings({ commit }: { commit: Commit }) {
            const result = await manager.getAllHubSettings();
            commit('setHubSettings', result);
        },
        async updateHubSettings({ commit }: { commit: Commit }, settings: HubSettings) {
          await manager.updateHubSettings(settings);
          commit('setHubSettings', settings);
      },
    },
    mutations = {
        setHubSettings(state: State, result: HubSettings) {
            state.settings = result;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
